body {
  background-color: #333333;
  color: #dddddd;
  font-size: 16px; /* Set a base font size */
  margin: 0;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

a {
  color: #dddddd;
}
a:hover {
  color: white;
}

.logo {
}

.facebook-link {
  text-decoration: none;
  color: inherit;
  padding-top: 20px;
  padding-bottom: 20px;
}

.facebook-link:hover {
  color: rgb(0, 166, 255);
}

.facebook-logo {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

img.trillions-kick {
}

.content {
  font-size: 1.25rem;
  padding-left: 20px;
  padding-right: 20px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .logo {
    max-width: 150px;
  }
  .facebook-link {
    font-size: 0.75rem;
  }
  img.trillions-kick {
    max-width: 360px; /* Limit width to 400px on mobile devices */
  }
  .content {
    font-size: 1rem;
  }
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center menu items horizontally */
  width: 100%; /* Ensure menu takes full width */
  margin-bottom: 20px;
}

.menu-item {
  font-size: 1.5rem;
  color: #dddddd;
  margin: 10px 16px;
  padding: 8px;
  cursor: pointer;
}

.menu-item:hover {
  color: #ffffff;
}

.selected {
  font-weight: bold;
  background-color: #000000;
  color: white;
}

.band-members {
  text-align: center;
}


.shows-table {
  font-size: 1rem;
  border: 2px solid #dddddd;
}
.shows-table tr {
  vertical-align: top;
}
.shows-table th {
  text-align: left;
  text-decoration: underline;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .social-media {
    flex-direction: row; /* Arrange items in a row */
    justify-content: center; /* Center items horizontally */
  }

  .facebook-link {
    margin-right: 20px; /* Add space between items */
    margin-bottom: 0; /* Remove bottom margin */
  }
}
